.App {
  //text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.label.error {
  color: red;
}

.link {
  background-color: rgba(0, 0, 0, 0);
  box-sizing: border-box;
  color: rgba(255, 255, 255, 0.75);
  cursor: auto;
  display: block;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  font-size: 16px;
  font-weight: normal;
  height: 40px;
  line-height: 24px;
  list-style-image: none;
  list-style-position: outside;
  list-style-type: none;
  padding-bottom: 8px;
  padding-left: 8px;  
  padding-right: 8px;
  padding-top: 8px;
  text-align: center;
  text-decoration: none;
}